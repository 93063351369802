import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import Layout from "../components/shared-layout";
import Breadcrumb from "../components/shared-breadcrumb";
import useStores from "../hooks/use-stores"
import VmButton from "../components/shared-button";
import VmModal from "../components/shared-modal";
import VmTable from "../components/shared-table";
import { navigate } from "gatsby";
import { GetCollegeId, ProdEnvCheck } from "../constants/options";
import { t } from "i18next";
import ContentLayout from "../components/shared-content-layout";
import { INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, TD_FIRST, TD_LAST, TD_NORMAL } from "../constants/style";
import TabPanel from '../components/shared-tab-panel';

const Traineeship = () => {

    const { userStore } = useStores();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentVisit, setCurrentVisit] = useState<any>();
    const [currentLcoationId, setCurrentLcoationId] = useState<any>();

    const [tabValue, setTabValue] = useState<number>(1);
    const [modalTabValue, setModalTabValue] = useState<number>(0);
    const [historyPage, setHistoryPage] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        if (userStore.studentProfile === null) {
            userStore.getStudentProfile()
        }
        setLoading(false)
    })

    const onRequestStudentProfile = () => {
        userStore.getStudentProfile()
    }

    if(loading) {
        return <h1>Loading</h1>
    }

    return (

        <ContentLayout
            pageName={t('TRAINEESHIP')}
            pageHeading={t('TRAINEESHIP')}
            breadCrumb={[]}
            showHeadingButton={false}
        >
            <div className="flex justify-between mt-4 mb-3">
                <Typography variant="h5" fontWeight={'bold'} marginTop={1}
                    sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
                    {t('TRAINEESHIP_LIST')}
                </Typography>
            </div>

            <VmTable
                loading={""}
                // page={historyPage}
                // paginationCount={userStore.visitHistoryList.totalCount == 0 ? 1 : userStore.visitHistoryList.totalCount / PAGE_SIZE}
                // onChangePagination={(event, pageNumber) => {
                //     setCurrentPage(pageNumber);
                //     userStore.getVisitList(true, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
                // }}
                thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('STATUS'), t('ACTION')]}
                tbody={
                    userStore.studentProfile.traineeships ?
                        userStore.studentProfile.traineeships.map((traineeship: any, index: number) => (
                            <>
                                <Box marginY={1} />
                                <tr key={index}>
                                    <td className={TD_FIRST} >{index + 1}</td>
                                    <td className={TD_NORMAL}>{traineeship.id}</td>
                                    <td className={TD_NORMAL} >{traineeship.qualification.name}</td>
                                    <td className={TD_NORMAL}>{traineeship.trainer.firstName} {traineeship.trainer.lastName}</td>
                                    <td className={TD_NORMAL} >
                                        <span className="bg-red-400 rounded-2xl text-white p-2 pt-1">
                                            <Typography variant="caption">{t('Checked Out by Teacher')}</Typography>
                                        </span>
                                    </td>
                                    <td className={TD_LAST}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => alert("RESCHEDULE BUTTON")}
                                        >
                                            {t('RESECHEDULE')}
                                        </Button>
                                    </td>
                                </tr>
                                <Box marginY={1} />
                            </>
                        ))
                        : <tr><td className="text-center py-4" colSpan={7}>{t('NO_RESULT_FOUND')}</td></tr>
                }
            />

        </ContentLayout>
    )
}

export default Traineeship;